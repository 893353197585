import React, { FC } from 'react';
import { PageLoader } from '@in/component-library';
import { useTranslations } from '../../hooks/use-translations';

const Loader: FC = () => {
  const { translations } = useTranslations();

  return (
    <div className="pageLoader">
      <PageLoader aria-label={translations.loading || 'Laster innhold.'} />
    </div>
  );
};

export default Loader;
