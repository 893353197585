import React, { FC } from 'react';
import { Body, LayoutGrid, Section } from '@in/component-library';
import { Header } from './Header';
import { Footer } from './Footer';
import SaveBar from './SaveBar/Savebar';
import { AppContext } from '../context/app-context';
import { GlobalMessages } from '@in/component-library';
import './Layout.scss';
import SkipToContent from './SkipToContent/SkipToContent';
import { useFormContext } from '../hooks/useFormContext';

export const Layout: FC<any> = (props: any) => {
  const { formState } = useFormContext();
  return (
    <AppContext.Consumer>
      {(appContext) => (
        <div className="container">
          <span className="top-element" tabIndex={-1}></span>
          <SkipToContent />
          <div className="layout-mainContent">
            <div className="layout-content">
              <Header />
              <Body id="maincontent" className="app-body">
                {formState.formCreated && <SaveBar />}
                <Section className="app-section">
                  <GlobalMessages messages={appContext.state.configuration.messages}></GlobalMessages>
                  <LayoutGrid>{props.children}</LayoutGrid>
                </Section>
              </Body>
            </div>
            <div className="layout-footer">
              <Footer />
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
