import React, { useState } from 'react';
import { TextArea } from '@in/component-library';

export const TextAreaFieldWrapper = (props: any) => {

  const [internalValue, setInternalValue] = useState<any>(props.value);

  return <TextArea 
    label = {props.label}
    hideLabel = { props.hideLabel}
    helpText = { props.helpText}
    helpTextRich = { props.helpTextRich}
    helpTextLabel = { props.helpTextLabel}
    description = { props.description}
    errorMsg = { props.errorMsg}
    focus = { props.focus}
    maxCount = { props.maxCount}
    autoResize = { props.autoResize}
    inputRef = { props.inputRef}
    disableResize = { props.disableResize}
    formField = { props.formField}
    value={internalValue}
    onBlur={props.onChange}
    onChange={(e) => setInternalValue(e.currentTarget.value)}
  />
}
