import React from 'react';
import { useLocation } from 'react-router';
import { useLocalizer } from '../../hooks';
import './SkipToContent.scss';

const SkipToContent = () => {
  const { l } = useLocalizer('Global');
  const location = useLocation();
  
  return (
    <>
      <div className="skip-to-content" aria-label={l('skipToContent')}>
        <ul>
          <li>
          <a className="skip-link" href={`${location.pathname}${location.search}#maincontent`}>
          {l('skipToContent')}
              {}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SkipToContent;
