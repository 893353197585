import { FaIcon } from "@in/component-library";
import React, { FC } from "react";
import "./ApplicationCard.scss";

export enum placeSecondaryTitle {
	AboveTitle = "above",
	BelowTitle = "below",
}
interface CardProps {
	title: string;
	secondaryTitle?: string;
	secondaryTitlePlacement?: placeSecondaryTitle;
	leftSideTitleIcon?: React.ReactNode;
	footerContent?: string | React.ReactNode;
	footerLabel?: string;
	footerLabelBackground?: boolean;
	footerIcon?: string;
	href?: string;
	children?: React.ReactNode;
}

const Card: FC<CardProps> = ({
	title,
	secondaryTitle,
	secondaryTitlePlacement = placeSecondaryTitle.AboveTitle,
	leftSideTitleIcon,
	footerContent,
	footerLabel,
	footerLabelBackground = false,
	footerIcon,
	href,
	children,
}: CardProps) => {
	return (
		<div className="card-component">
			<a className="card" href={href}>
				<div className="card__wrapper">
					<div className="card__content-main">
						<div>
							{leftSideTitleIcon && (
								<span className="card__icon-left">
									<FaIcon name={leftSideTitleIcon} size="1x" />
								</span>
							)}
							<span className="card__icon-right">
								<FaIcon name="fal fa-arrow-right" size="1x" />
							</span>
							<h2 className="card__title"> {title} </h2>
						</div>

						<p
							className={`card__secondary-title ${
								secondaryTitlePlacement === placeSecondaryTitle.BelowTitle &&
								`card__secondary-title-${placeSecondaryTitle.BelowTitle}`
							}`}
						>
							{secondaryTitle}
						</p>
					</div>

					<div className="card__content-body">
						<div className="card__children">
							{children ? (
								children
							) : (
								<>
									<div className="card__content">
										{footerContent}
										<div className="card__label">
											<FaIcon
												className="card__label-icon"
												name={footerIcon}
												size="1x"
											/>
											<span
												className={`${
													footerLabelBackground ? "card__tag-bg" : "card__tag "
												}`}
											>
												{footerLabel}
											</span>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</a>
		</div>
	);
};

export default Card;
