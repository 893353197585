import useSWR from "swr";
import { getAccount } from "../apis/get-account";

export const useAccount = () => {
  const { data, error } = useSWR(["api/accounts/selected"], getAccount);

  return {
    loading: !data && !error,
    error,
    account: data,
  };
};
