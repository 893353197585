import { ApiException, ApiResponse } from "./api-error";

export const get = async <T>(url: string, returnOnError?: boolean) => await fetchHandler<T>(url, returnOnError);

export const post = async (url: string, body: any, options?: RequestInit) => {
  return await mutateHandler(url, {
    ...options,
    body: JSON.stringify(body),
    method: "POST",
  });
};

export const put = async (url: string, body: any, options?: RequestInit) => {
  return await mutateHandler(url, {
    ...options,
    body: JSON.stringify(body),
    method: "PUT",
  });
};

export const del = async (url: string) => {
  return await mutateHandler(url, {
    method: "DELETE",
  });
};

const fetchHandler = async <T>(
  url: string,
  returnOnError?: boolean,
  request?: RequestInit
): Promise<T> => {
  const res = await fetchInternal(url, request);

  if (!res.ok) {
    if (res.status === 401) {
      window.location.assign("/log-in");
    }
    if (!returnOnError && res.status === 500) {
       window.location.assign("/error");
    }

    const error = new ApiException("An error occurred while fetching data.");
    error.code = res.status;
    throw error;
  }

  return formatResponse(res);
};

const fetchInternal = async (url: string, request?: RequestInit) => {
  return await fetch(`/api/${url}`, {
    ...request,
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const mutateHandler = async (
  url: string,
  request?: RequestInit
): Promise<ApiResponse> => {
  const res = await fetchInternal(url, request);

  return { code: res.status, body: res.status === 500 ? res.text() : await formatResponse(res) };
};

const formatResponse = (response: Response) => 
  response.text().then((text) => {
    return text ? JSON.parse(text) : {};
  });
