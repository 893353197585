import React from 'react';
import { Checkbox, DateInput, Select } from '@in/component-library';
import { TextAreaFieldWrapper } from './Fields/TextAreaFieldWrapper';
import { InputFieldWrapper } from './Fields/InputFieldWrapper';
import { RadioButtonWrapper } from './Fields/RadioButtonFieldWrapper';
import { FileUploadWrapper } from './Fields/FileUploadFieldWrapper';
import { InlineReadOnlyTextFieldWrapper } from './Fields/InlineReadOnlyTextFieldWrapper';
import { GroupFieldWrapper } from './Fields/GroupFieldWrapper';
import cn from 'classnames';
import './FormField.scss';
import { useFormContext } from '../../hooks/useFormContext';
import { FormActionTypes } from '../../context/form-reducer';

interface FieldMap<T> {
  [key: string]: T;
}

const FieldMap: FieldMap<React.FC<any>> = {
  textField: InputFieldWrapper,
  textAreaField: TextAreaFieldWrapper,
  selectField: Select,
  checkboxField: Checkbox,
  radioButtonField: RadioButtonWrapper,
  dateField: DateInput,
  numberField: InputFieldWrapper,
  fileUploadField: FileUploadWrapper,
  inlineReadOnlyTextField: InlineReadOnlyTextFieldWrapper,
  groupField: GroupFieldWrapper,
  autocompleteOrganizationTextField: InputFieldWrapper,
};

export const FormField: React.FC<{
  onChange: (event: any) => void;
  onFileUpload: (file: any) => Promise<any>;
  onFileDelete: (id: string) => Promise<any>;
  field: any;
}> = (props) => {
  const { formState, dispatch } = useFormContext();

  const field = formState.form.fields.find((f: any) => f.key === props.field.key);

  const validation = formState.validationByFieldName[props.field.name] ?? undefined;
  const errorMessage = validation !== undefined && validation.length > 0 && validation.filter((v) => v.length > 0) ? validation.join(', ') : undefined;

  const FieldComponent = !field.hidden && FieldMap[field.fieldType];

  const focus = formState.focusField === field.name;

  const {
    key: _0, 
    sanityCreatedAt: _1,
    sanityUpdatedAt: _2,
    sanityType: _3,
    sanityRevision: _4,
    validationRules: _5,
    staticValue: _6,
    initialValue: _7,
    sanityKey: _8,
    ...fieldWithOnlyRequiredProps } = field;

  return (
    <React.Fragment key={`${field.key}`}>
      <div id={field.name} className={cn('form-field', { [`${[field.fieldType]}Wrapper`]: field.fieldType !== undefined })}>
        {FieldComponent && (
          <FieldComponent
            {...fieldWithOnlyRequiredProps}
            disabled={field.readOnly === true}
            errorMsg={errorMessage}
            value={field.value ?? ''}
            onChange={(event: any) => {
              props.onChange(event);
              dispatch({ type: FormActionTypes.FocusField, payload: '' });
            }}
            onFileUpload={props.onFileUpload}
            onFileDelete={props.onFileDelete}
            focus={focus === true ? true : undefined}
          />
        )}
        {!FieldComponent && !field.hidden && (
          <>
            {field.label} - <em>{field.fieldType}</em>: {field.value}
          </>
        )}
      </div>
    </React.Fragment>
  );
};
