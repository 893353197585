import React, { FC } from 'react';
import { Message } from '../../models/statuspage/message';
import FormStatusMenuMobile from './form-status-menu-mobile';
import FormStatusMenuDesktop from './form-status-menu-desktop';
import { useTranslations } from '../../hooks/use-translations';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import { useMediaQuery } from '@in/component-library';

export interface FormStatusMenuProps {
  formId: string | undefined;
  projectUrl?: string;
  formStatus: ApplicationStatus | undefined;
  unseenMessages?: Message[];
  selectedMenuElement: (tabIndex: number) => void;
  activeTab: number;
}

const FormStatusMenu: FC<FormStatusMenuProps> = ({ formId, projectUrl, formStatus, unseenMessages, selectedMenuElement, activeTab }) => {
  const { translations } = useTranslations();

  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const mediaQueryMobile = useMediaQuery('(max-width: 64em)');
  
  React.useEffect(() => {
    mediaQueryMobile !== undefined && setIsMobile(mediaQueryMobile.matches);
  }, [mediaQueryMobile]);

  const url = `/form/${formId}/status`; 

  const navItems = [
    { 
      value: translations.formStatusTabStatus, 
      text: translations.formStatusTabStatus, 
      href: `${url}/0` },
    {
      value: translations.formStatusMessagesTitle,
      text: translations.formStatusMessagesTitle,
      href: `${url}/1`,
      unseenMessages: unseenMessages?.length,
    },
    {
      value: translations.formStatusYourInquiry,
      text: translations.formStatusYourInquiry,
      href: `${url}/2`,
    },
  ];

  const handleSelectChange = (value: string) => {
    const selectedTab = navItems.find(option => option.value === value);
  selectedTab && selectedMenuElement(navItems.indexOf(selectedTab));
  };

  return isMobile ? (
    <FormStatusMenuMobile
      navItems={navItems}
      selectedOption={navItems[activeTab].value}
      handleSelectChange={handleSelectChange}
      formId={formId}
      projectUrl={projectUrl}
      formStatus={formStatus}
      unseenMessages={unseenMessages}
      selectedMenuElement={selectedMenuElement}
      activeTab={activeTab}
    />
  ) : (
    <FormStatusMenuDesktop
      navItems={navItems}
      activeTab={activeTab}
      formId={formId}
      projectUrl={projectUrl}
      formStatus={formStatus}
      unseenMessages={unseenMessages}
      selectedMenuElement={selectedMenuElement}
    />
  );
};

export default FormStatusMenu;