import { LocalizedString } from '../../utils/localizer';

export const formAction: LocalizedString = {
  PayoutRequestGrantOpenForm: {
    nb: 'Fortsett utbetaling'
  },
  PayoutRequestGrantCreateForm: {
    nb: 'Utbetal tilskudd'
  }
}
