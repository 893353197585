export enum FileStatus {
  Ready,
  Uploading,
  ReadyForUpload,
  Deleting,
  NotValid,
  Error
}

export interface FileItem {
  id: string;
  file?: File;
  fileSize: number;
  fileName: string;
  status: FileStatus;
  fileType: string;
  message?: string;
  fieldName?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  totalNumberOfFiles?: number;
};

export interface Confirmation {
  text: string;
  isConfirmed: boolean;
}

export enum FormValidationType {
  Field = "Field",
  Page = "Page",
  Form = "Form"
}

export interface FormError {
  id: number;
  errorMessage: string;
}
