import React, { FC, useEffect, useContext } from 'react';
import { AppContext } from '../../context/app-context';

interface UXSignalsWidgetProps {
  isReadyToLoadScript: boolean;
  id: string;
}

const UXSignalsWidget: FC<UXSignalsWidgetProps> = ({ isReadyToLoadScript, id }) => {
  const { state } = useContext(AppContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://widget.uxsignals.com/embed.js';
    if (isReadyToLoadScript) {
      document.body.appendChild(script);
    }

    return () => {
      try {
        document.body.removeChild(script);
      } catch {}
    };
  }, [isReadyToLoadScript]);

  return state.configuration.featureFlags?.uxSignalsEnabled ? (
    <div className="uxsignals" data-uxsignals-embed={id} data-uxsignals-mode={state.configuration.featureFlags.useUxSignalsDemo ? 'demo' : ''}></div>
  ) : null;
};

export default UXSignalsWidget;
