import useSWR from "swr";

import { getTranslations } from "../apis/get-translations";
import { Labels } from "../models/statuspage/labels";

const EMPTY_TRANSLATIONS = {} as Labels;

export const useTranslations = () => {
  const { data, error } = useSWR(["api/translations"], getTranslations);

  return {
    loading: !data && !error,
    error,
    translations: data ?? EMPTY_TRANSLATIONS,
  };
};
