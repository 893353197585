import React, { FC, useContext } from 'react';
import { Header as BaseHeader, Icons, Language as BaseLanguage } from '@in/component-library';
import { AppContext } from '../context/app-context';
import { Language } from '../models';
import { setLanguage } from '../services/session.service';
import { useLocalizer } from '../hooks';
import { ConfigurationActionTypes } from '../context/configuration-reducer';
import { useLocation } from 'react-router';

export const Header: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { L } = useLocalizer('Header');

  const langClicked = async (event: React.MouseEvent<HTMLButtonElement>, lang: Language) => {
    event.preventDefault();

    await setLanguage(lang);

    // Update state
    dispatch({
      type: ConfigurationActionTypes.SetLanguage,
      payload: lang,
    });
  };

  const menuItems: Array<any> = [
    {
      icon: Icons.Change,
      href: '/choose-account',
      text: L('chooseAccount'),
    },
    {
      href: '/sign-out',
      text: L('signOut'),
    },
  ];

  const navItems: Array<any> = state.configuration.navItems.map((ni) => {
    return {
      text: ni.name ?? '',
      href: ni.url,
    };
  });

  const languages: Array<BaseLanguage> = [
    {
      name: 'Bokmål',
      isSelected: state.configuration.language === Language.nb,
      code: Language.nb,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => langClicked(event, Language.nb),
      url: `${useLocation().pathname}`,
    },
    {
      name: 'Nynorsk',
      isSelected: state.configuration.language === Language.nn,
      code: Language.nn,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => langClicked(event, Language.nn),
      url: `${useLocation().pathname}`,
    },
    {
      name: 'English',
      isSelected: state.configuration.language === Language.en,
      code: Language.en,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => langClicked(event, Language.en),
      url: `${useLocation().pathname}`,
    },
  ];

  return (
    <BaseHeader
      name={state.user.currentUser.displayName}
      companyName={state.user.currentUser.companyName}
      loggedInAsLabel={L('loggedInAs')}
      menuItems={menuItems}
      navItems={navItems}
      logoAriaLabel={'Rask Avklaring'}
      logoLink={state.configuration.navItems.find((ni) => ni.name === 'startPage')?.url ?? '#'}
      languagesLabel={L('languages')}
      languages={languages}
      className="base-header"
      hotJarClass="data-hj-suppress"
    ></BaseHeader>
  );
};
