import React from 'react';
import { FormSummaryField } from '../FormSummaryField';
import './GroupSummaryField.scss';

export const GroupSummaryField = (props: any) =>
  <div className="groupSummaryField">

    <label className="summaryFieldLabel">{props.label}</label>
    {props.fields.map((field: any, index: number) => (
      <FormSummaryField
        key={index}
        {...props}
        field={{
          key: field.key,
          name: field.name,
          fieldType: field.fieldType,
        }}
      />
    ))}
  </div>
