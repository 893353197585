import React from 'react';
import { InputField } from '@in/component-library';

export const InputFieldWrapper = (props: any) => {

  const type = props.fieldType.replace('Field', '');
  
  return <InputField 
    label = { props.label}
    hideLabel = { props.hideLabel}
    helpText = { props.helpText}
    helpTextRich = { props.helpTextRich}
    helpTextLabel = { props.helpTextLabel}
    description = { props.description}
    errorMsg = { props.errorMsg}
    hideError = { props.hideError}
    focus = { props.focus}
    textAlignRight = { props.textAlignRight}
    maxCount = { props.maxCount}
    suffix = { props.suffix}
    autoComplete = { props.autoComplete}
    icon = { props.icon}
    iconPlacement = { props.iconPlacement}
    inputRef = { props.inputRef}
    maxLength = { props.maxLength}
    id = { props.id}
    maxWidth = { props.maxWidth}
    formField = { props.formField}
    onBlur={props.onChange}
    onChange={() => {}}
    type={type}
    value={props.value}
  />
}
