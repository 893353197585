import React from 'react';
import { useFormContext } from '../../../hooks/useFormContext';
import { FormField } from '../FormField';
import { mapPageFields } from '../fieldMapper';
import './FormPageWrapper.scss';
import { FormSummaryValidation } from '../FormSummaryValidation';

export const FormPageWrapper: React.FC<{
  page: any;
  goToPage: (pageNumber: number) => void;
  goToField: (steps: number, fieldId: string) => void;
  onFieldChange: (event: any, field: any) => void;
  onFileUpload: (file: any, field: any) => Promise<any>;
  onFileDelete: (id: string, field: any) => Promise<any>;
}> = (props) => {
  const page = props.page;
  const { formState } = useFormContext();

  const mappedFields = mapPageFields(page, formState, true);

  const hasError = mappedFields.filter((f: any) => f.validations.length > 0).length > 0 && formState.summaryShown;

  return (
    <>
      <h2 className="h2AdjustedForTopBorder">{page.title}</h2>
      {hasError && <FormSummaryValidation goToField={props.goToField} />}
      {page.description && <div className="description" dangerouslySetInnerHTML={{ __html: page.description }} />}
      {props.page.fields?.map((field: any, i: number) => (
        <FormField
          field={field}
          key={i}
          onChange={(event: any) => props.onFieldChange(event, field)}
          onFileUpload={(file: any) => props.onFileUpload(file, field)}
          onFileDelete={(id: string) => props.onFileDelete(id, field)}
        />
      ))}
    </>
  );
};
