
export const fetchSanityData = async <T>(query: string): Promise<T[]> => {
  try {
    const url = new URL(`/sanity/query`, window.location.origin);
    url.searchParams.append('query', query);
    const response = await fetch(url.toString());

    if (!response.ok) throw new Error(`Error: ${response.status}`);

    const data: { query: string; result: T[] } = await response.json();
    return data.result;

  } catch (error) {
    console.error('Error fetching sanity data:', error);
    throw error;
  }
};