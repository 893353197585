import { LocalizedString } from '../../utils/localizer';

export const form: LocalizedString = {
  pageValidationErrorTitle: {
    nb: 'Skjemaet kan ikke sendes inn.',
    nn: 'Skjemaet kan ikkje sendast inn.',
    en: 'The form cannot be submitted.',
  },
  formPageValidationSummaryTitle: {
    nb: 'Skjemaet kan ikke sendes inn på grunn av følgende feil:',
    nn: 'Skjemaet kan ikkje sendast inn på grunn av følgande feil:',
    en: 'The form cannot be submitted due to these errors:',
  },
  noConfirmationChosenMessage: {
    nb: 'Vennligst bekreft før innsending.',
    nn: 'Ver venleg og stadfest før innsending.',
    en: 'Please confirm before submission.',
  },
  jumpToFieldAndCorrect: {
    en: 'Click to correct field for:',
    nb: 'Klikk for å rette felt for:',
    nn: 'Klikk for å rette felt for:',
  },
  displayValidationErrorText: {
    en: 'Show more',
    nb: 'Vis mer',
    nn: 'Vis meir',
  },
  displayFieldValidationErrorText: {
    en: 'display error',
    nb: 'vis feil',
    nn: 'vis feil',
  },
  displayLess: {
    en: 'Show less',
    nb: 'Vis mindre',
    nn: 'Vis mindre',
  },
  continueTitle: {
    en: 'Continue where you left off',
    nb: 'Fortsett der du slapp',
    nn: 'Fortsett der du slapp',
  },
  continueSubTitle: {
    en: 'There are some enquiries that are still in progress',
    nb: 'Det er noen henvendelser som fortsatt er i arbeid',
    nn: 'Det er nokre førespurnader som framleis er i arbeid',
  },
  createNewForm: {
    en: 'Create new enquiry',
    nb: 'Opprett ny henvendelse',
    nn: 'Opprett ny henvendelse',
  },
  FastForward: {
    en: 'Inquiry',
    nb: 'Henvendelse',
    nn: 'Henvendelse',
  },
  FastForwardv3: {
    en: 'Inquiry',
    nb: 'Henvendelse',
    nn: 'Henvendelse',
  },
  GreenPlatform: {
    en: "Green platform",
    nb: "Grønn plattform",
    nn: "Grønn plattform"
  },
  lastSave: {
    en: 'Last edited ',
    nb: 'Sist endret ',
    nn: 'Sist endret ',
  },
  draft: {
    en: 'Draft',
    nb: 'I arbeid',
    nn: 'I arbeid',
  },
  stepErrorOnPageLabel: {
    en: 'This page has errors.',
    nb: 'Denne siden har feil.',
    nn: 'Denne sida har feil',
  },
  stepCompletedLabel: {
    en: 'This page is completed.',
    nb: 'Denne siden er fullført.',
    nn: 'Denne sida er fullført.',
  },
  stepLabel: {
    en: '{t}. Page {p} of {n}.',
    nb: '{t}. Side {p} av {n}.',
    nn: '{t}. Side {p} av {n}.',
  },
  stepperLabel: {
    en: 'See if you can get help',
    nb: 'Sjekk om du kan få hjelp',
    nn: 'Sjå om du kan få hjelp',
  },
  stepperCount: {
    en: 'out of',
    nb: 'ut av',
    nn: 'ut av',
  },
  connectionLostError: {
    en: 'We\'ve encountered an error. Please reload the page. If the issue persists please contact Innovation Norway customer support.',
    nb: 'Det har oppstått en feil. Vennligst last siden på nytt. Hvis problemet vedvarer vennligst ta kontant med Innovasjon Norges kundesupport.',
    nn: 'Det har oppstått ein feil. Ver venleg og last sida på nytt. Om problemet held fram, ta kontakt med Innovasjon Noreg sin kundestøtte.'
  }
};
