import { useContext } from "react";
import { LocalizationContext, LocalizeOptions } from "../context/localization-context";

const useLocalizer = (namespace: string) => {
    const ctx = useContext(LocalizationContext);

    const l = (key: string, options?: LocalizeOptions) => ctx(namespace, key, options);

    const L = (key: string) => l(key, { capitalize: true });

    const globalL = (key: string, options?: LocalizeOptions) => ctx("Global", key, options);

    return { l, L, globalL };
}

export { useLocalizer };
