import React, { FC, useState } from 'react';
import './feedback.scss';
import { useParams } from 'react-router-dom';
import { setFeedbackMessage } from '../../apis/set-message-feedback';
import { Message } from '../../models/statuspage/message';
import { StarRatingInput } from '@in/component-library';
import { useTranslations } from '../../hooks/use-translations';

interface FeedbackProps {
  message: Message;
  selectedValue?: string | null;
}

const Feedback: FC<FeedbackProps> = ({ message, selectedValue }) => {
  const { translations } = useTranslations();
  const { formId } = useParams<{ formId: string }>();
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [selectedStars, setSelectedStars] = useState(selectedValue);

  const onFeedbackSubmit = async (feedback: string) => {
    if (formId) {
      const response = await setFeedbackMessage(formId, message.id, feedback);
      if (response.code === 204) {
        setShowThankYou(true);
        setSelectedStars(feedback);
      }
    }
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === null) return;
    onFeedbackSubmit(e.target.value);
  };

  return (
    <>
      <div className="form-status__feedback-wrapper">
        <p>{translations.formStatusMessageFeedbackHeader}</p>
        <StarRatingInput
          className="feedback__stars"
          name="feedback"
          onChange={handleSubmit}
          label={translations.formStatusMessageFeedbackLabel}
          options={new Array(5).fill(null).map((_, i) => {
            const value = (i + 1).toString();
            return {
              label: value,
              value: value,
              selected: selectedStars === value,
            };
          })}
        />

        {showThankYou && (
          <span aria-live="polite" className="feedback__thank-you-message">
            {translations.formStatusThankYouMessage}
          </span>
        )}
      </div>
    </>
  );
};

export default Feedback;
