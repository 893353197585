import { useContext } from 'react';
import { AppContext } from '../context/app-context';
import { FormActions } from '../context/form-reducer';

export const useFormContext = () => {
  const { state, dispatch } = useContext(AppContext);

  return {
    formState: state.form,
    dispatch: (payload: FormActions) => dispatch(payload),
  }
}
