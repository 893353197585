import React, { FC, useContext } from 'react';
import { useTranslations } from '../../hooks/use-translations';
import { MessageBubbleProps } from './message-bubble';
import { format } from 'date-fns';
import { nb, nn, enGB } from 'date-fns/locale';
import { AppContext } from '../../context/app-context';

const MessageHeader: FC<MessageBubbleProps> = ({ message, display = '--column' }) => {
  const { translations } = useTranslations();
  const { state } = useContext(AppContext);

  const localeMapping = {
    nb: nb,
    nn: nn,
    enGB: enGB,
  };

  const formattedDateTime = format(new Date(message.createdOn), 'HH:mm - dd.MMMM.yyyy', {
    locale: localeMapping[translations.formStatusDateLanguage as 'nb' | 'nn' | 'enGB'],
  });

  return (
    <>
      {message && message.author && (
        <div className={`messages__header messages${display}`}>
          <span className="data-hj-suppress">
            {translations.formStatusSender}:{' '}
            {message.author === state.user.currentUser.displayName ? state.user.currentUser.displayName.toLocaleLowerCase() : message.author.toLocaleLowerCase()}
          </span>

          <span className="messages__header--dato">{formattedDateTime}</span>
        </div>
      )}
    </>
  );
};

export default MessageHeader;
