import { post } from "./utils";

export const postSeenByRecipient = async (
  formId: string | undefined,
  messageId: number
) =>
  post(
    `messages/${formId}/seenByRecipient/${
      messageId 
    }`,
    null
  );