//This function may be expanded if information from signalR needs to be reworked in the frontend
const formSanitizer = (form: any) => {
  const sanitizedFields = form.fields.map((field: any) => {
    if (field.fieldType === 'selectField') {
      const sanitizedOptions = field.options.map((option: any) => {
        !option.value && (option.value = option.text);
        return option;
      });
      field.options = sanitizedOptions;
    }
    if (field.fieldType === 'checkboxField') {
      const sanitizedOptions = field.options.map((option: any) => {
        !option.value && (option.value = option.text);
        return option;
      });
      field.options = sanitizedOptions;
    }
    if (field.fieldType === 'radioButtonField') {
      const sanitizedOptions = field.options.map((option: any) => {
        !option.value && (option.value = option.text);
        return option;
      });
      field.options = sanitizedOptions;
    }
    return field;
  });
  form.fields = sanitizedFields;
  return form;
};
export default formSanitizer;
