import React from 'react';
import { Summary } from '@in/component-library';

export const InputSummaryField = (props: any) => {
  return (
    <Summary onClick={props.goToField && (() => props.goToField())} name={props.label} ariaBtnText={`Rediger feltet "${props.label}"`}>
      <p className="data-hj-suppress">{props.value}</p>
    </Summary>
  );
};
