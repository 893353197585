import React from 'react';
import { RecommendedCourseProps } from './recommended-course-props';
import { RecommendedCourse } from './recommended-course';
import { Recommendation } from './recommendation';

const RecommendationCourseWrapper: React.FC<RecommendedCourseProps> = (props) => {
  if (props?.course.caseHandlerAEnabled) {
    return <RecommendedCourse {...props} />;
  }
  if (props?.course.caseHandlerBEnabled) {
    return <Recommendation {...props} />;
  }
  return <></>;
};

export default RecommendationCourseWrapper;
