import React, { FC } from 'react';

import { Button } from '@in/component-library';

const CookieSettings: FC = () => {
  const showCookieSettings = () => {
    window.OneTrust?.ToggleInfoDisplay();
  };

  return (
    <Button size="medium" theme="neutral" variant="outlined" onClick={showCookieSettings}>
      Cookie Settings
    </Button>
  );
};

export default CookieSettings;
