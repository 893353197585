import React, { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/app-context';

export const FormsInit: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useContext(AppContext);

  const setFormType = (search: string, formType: string) => {
    if (search.includes('FastForward') && !search.includes('FastForwardv')) {
      return searchParams.toString().replace('FastForward', formType);
    }
    if (search === '') {
      return `formType=${formType}`;
    }
    if (!search.includes('formType')) {
      return searchParams.toString() + `&formType=${formType}`;
    }
    return search;
  };

  const getSearchParams = () => {
    const search = searchParams.toString();
    if (state.user.currentUser.accountType === 'Person') {
      return setFormType(search, 'FastForwardv3');
    } else if (state.configuration.featureFlags.redirectToFastForwardCompanyEnabled && state.user.currentUser.accountType === 'Company') {
      return setFormType(search, 'FastForwardCompany');
    }
    return searchParams.toString();
  };
  useEffect(() => {
    navigate(
      {
        pathname: `/form/${crypto.randomUUID()}|${state.user.currentUser.customerAccountId}/0`,
        search: getSearchParams(),
      },
      { replace: true }
    );
  }, [searchParams, state.user.currentUser.accountType]);

  return <></>;
};
