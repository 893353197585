export class ApiException extends Error {
  code: number = 0;
  info: any = undefined;

  constructor(m: string) {
    super(m);

    Object.setPrototypeOf(this, ApiException.prototype);
  }
}

export interface ApiResponse {
  code: number;
  body: any;
}
