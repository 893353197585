import React from 'react';
import './InlineReadOnlyTextSummaryField.scss';

export const InlineReadOnlyTextSummaryField = (props: any) => {
  return (
    <div className="inlineReadOnlyTextField">
      <label htmlFor={props.name}>{props.label}</label>
      <span id={props.name} className="inlineReadOnlyTextField-value data-hj-suppress">{props.value}</span>
    </div>
  );
}
