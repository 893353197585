import React, { FC, useContext } from 'react';
import MessageBubble, { MessageBubbleProps } from './message-bubble';
import MessageHeader from './message-header';
import { AppContext } from '../../context/app-context';

const MessageThread: FC<MessageBubbleProps> = ({ message }) => {
  const { state } = useContext(AppContext);
  const displayName = state.user.currentUser.displayName;
  const flexDirection = message?.author === displayName ? '--right' : '--left';

  return (
    <div className={`messages`}>
      <div className={`messages__wrapper messages__wrapper${flexDirection}`}>
        <MessageHeader message={message} />
        <MessageBubble message={message} display={flexDirection} />
      </div>
    </div>
  );
};

export default MessageThread;
