import React, { createContext, useReducer, Dispatch, useState } from 'react';
import { Language, UserStatus } from '../models';
import { userReducer, UserActions, UserActionTypes, UserState, initialUserState } from './user-reducer';
import { ConfigurationActions, ConfigurationActionTypes, configurationReducer, ConfigurationState, initialConfigurationState } from './configuration-reducer';
import { FormActions, formReducer, FormState, initialFormState } from './form-reducer';
import * as sessionService from '../services/session.service';

type AppState = {
  user: UserState;
  configuration: ConfigurationState;
  form: FormState;
};

const initialState: AppState = {
  user: initialUserState,
  configuration: initialConfigurationState,
  form: initialFormState,
};

const AppContext = createContext<{
  state: AppState;
  dispatch: Dispatch<UserActions | ConfigurationActions | FormActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const appReducer = ({ user, configuration, form }: AppState, action: UserActions | ConfigurationActions | FormActions) => ({
  user: userReducer(user, action as UserActions),
  configuration: configurationReducer(configuration, action as ConfigurationActions),
  form: formReducer(form, action as FormActions),
});

const AppProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [localLanguage, setLocalLanguage] = useState(initialState.configuration.language);

  const initContext = async () => {
    try {
      const sessionConfig = await sessionService.getCurrentConfiguration();
      dispatch({ type: ConfigurationActionTypes.SetLanguage, payload: (sessionConfig.language ?? 'nb') as Language });
      dispatch({ type: ConfigurationActionTypes.SetNavItems, payload: sessionConfig.navItems ?? [] });
      dispatch({ type: ConfigurationActionTypes.SetMessages, payload: sessionConfig.messages ?? [] });
      dispatch({ type: ConfigurationActionTypes.SetFeatureFlags, payload: sessionConfig.featureFlags ?? initialConfigurationState.featureFlags });

      if (sessionConfig.user === undefined || sessionConfig.user === null) {
        window.location.href = `/login?redirect=${btoa(window.location.pathname + window.location.search)}`;
      } else {
        dispatch({ type: UserActionTypes.SetStatus, payload: UserStatus.LOGGED_IN });
        dispatch({ type: UserActionTypes.SetUser, payload: sessionConfig.user });
      }
    } catch (error) {
      window.location.href = `/login?redirect=${btoa(window.location.pathname + window.location.search)}`;
    }
  };

  React.useEffect(() => {
    if (state.user.status === UserStatus.LOGGED_OUT || localLanguage !== state.configuration.language) {
      initContext();
      setLocalLanguage(state.configuration.language);
    }
  }, [state.user.status, state.configuration.language, localLanguage]);

  return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
