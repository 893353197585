import React from 'react';
import { Summary } from '@in/component-library';
import './CheckboxSummaryField.scss';

export const CheckboxSummaryField = (props: any) => (
  <Summary onClick={props.goToField && (() => props.goToField())} name={props.label} ariaBtnText={`Rediger feltet "${props.label}"`}>
    <ul>
      {props.options
        .filter((o: any) => o.selected)
        .map((o: any, index: number) => (
          <li className="checkbox-summary-field data-hj-suppress" key={index}>
            {o?.text}
          </li>
        ))}
    </ul>
  </Summary>
);
