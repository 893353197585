import React, { useState } from 'react';
import { Notification, Icon, Icons, IconSize, Summary } from '@in/component-library';
import './FileUploadSummaryField.scss';

const FileItem: React.FC<{ file: any; onClick: () => Promise<any> }> = (props) => {
  const [clicked, setClicked] = useState<Boolean>(false);

  const onFileClick = async () => {
    setClicked(true);
    await props.onClick();
    setClicked(false);
  };

  return (
    <button className="linkButton data-hj-suppress" onClick={() => onFileClick()}>
      {!clicked && <Icon name={Icons.File} size={IconSize.Small} inline className="link-button-icon" />}
      {clicked && <Icon name={Icons.Spinner} size={IconSize.Small} inline className="link-button-icon" />}
      {props.file.name}
    </button>
  );
};

export const FileUploadSummaryField = (props: any) => {
  return (
    <Summary onClick={props.goToField && (() => props.goToField())} name={props.label} ariaBtnText={`Rediger feltet "${props.label}"`}>
      <div className="fileUploadSummaryField">
        {props.attachments && (
          <ul>
            {props.attachments.map((a: any) => (
              <li key={a.uploadName}>
                <FileItem file={a} onClick={() => Promise.resolve(props.onFileClick(a))} />
              </li>
            ))}
          </ul>
        )}
        {props.attachments?.length === 0 && props.noFilesMessage && (
          <Notification fullWidth type="generic">
            {props.noFilesMessage}
          </Notification>
        )}
      </div>
    </Summary>
  );
};
