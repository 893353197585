import { Localizations } from '../../utils/localizer';
import { footer } from './localizationsFooter';
import { form } from './localizationsForm';
import { formAction } from './localizationsFormAction';
import { VMAPartners } from './localizationsVMAPartners';

const localizations: Localizations = {
  Global: {
    loading: {
      en: 'Loading...',
      nb: 'Laster...',
      nn: 'Lastar...',
    },
    skipToContent: {
      en: 'Skip to main content',
      nb: 'Hopp til innhold',
      nn: 'Hopp til innhald',
    },
  },
  Header: {
    chooseAccount: {
      nb: 'bytt aktør',
      nn: 'byt aktør',
      en: 'change actor',
    },
    loggedInAs: {
      nb: 'logget inn som',
      nn: 'logga inn som',
      en: 'logged in as',
    },
    languages: {
      nb: 'språk',
      en: 'languages',
      nn: 'språk',
    },
    startPage: {
      en: 'My Page',
      nb: 'Min Side',
      nn: 'Min Side',
    },
    applications: {
      en: 'Applications',
      nb: 'Søknader',
      nn: 'Søknader',
    },
    accounts: {
      en: 'Account overview',
      nb: 'Kontooversikt',
      nn: 'Kontooversikt',
    },
    courses: {
      en: 'Courses',
      nb: 'Kurs',
      nn: 'Kurs',
    },
    signOut: {
      en: 'Sign out',
      nb: 'Logg ut',
      nn: 'Logg ut',
    },
  },
  Menu: {
    forms: {
      en: 'My applications',
      nb: 'Mine søknader',
      nn: 'Mine søknader',
    },
  },
  Footer: footer,
  Form: form,
  FormAction: formAction,
  VMAPartners: VMAPartners,
};

export { localizations };
