import React, { FC } from 'react';
import { Select } from '@in/component-library';
import { FormStatusMenuProps } from './form-status-menu';

interface FormStatusMobileMenuProps extends FormStatusMenuProps {
  navItems: any[];
  selectedOption: string;
  handleSelectChange: (value: string) => void;
}

const FormStatusMenuMobile: FC<FormStatusMobileMenuProps> = ({ navItems, selectedOption, handleSelectChange }) => {
  return (
    <div className="form-status-menu__mobile">
      <Select className="dropdown" name="dropdown" label="dropdown" hideLabel options={navItems} value={selectedOption} onChange={(value) => handleSelectChange(value)} />
    </div>
  );
};

export default FormStatusMenuMobile;