import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './styles/style.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './context/app-context';
import { LocalizationProvider } from './context/localization-context';
import { HelmetProvider } from 'react-helmet-async';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    <AppProvider>
      <LocalizationProvider>
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>
      </LocalizationProvider>
    </AppProvider>
  </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
