import { LocalizedString } from '../../utils/localizer';

export const footer: LocalizedString = {
  firstColumnHeading: {
    en: 'Contact',
    nb: 'Kontakt',
    nn: 'Kontakt',
  },
  firstColumnParagraph1: {
    en: 'Tel: (+47) 22 00 25 00',
    nb: 'Tlf: (+47) 22 00 25 00',
    nn: 'Tlf: (+47) 22 00 25 00',
  },
  firstColumnParagraph2: {
    en: 'Grev Wedels plass 9, 0151 Oslo',
    nb: 'Grev Wedels plass 9, 0151 Oslo',
    nn: 'Grev Wedels plass 9, 0151 Oslo',
  },
  firstColumnParagraph3: {
    en: 'Pb. 448 Sentrum, 0104 Oslo',
    nb: 'Pb. 448 Sentrum, 0104 Oslo',
    nn: 'Pb. 448 Sentrum, 0104 Oslo',
  },
  firstColumnParagraph4: {
    en: 'Normal opening hours at 8-16',
    nb: 'Normal åpningstid kl. 8-16',
    nn: 'Normal opningstid kl. 8-16',
  },
  secondColumnHeading: {
    en: 'About the website',
    nb: 'Om nettsiden',
    nn: 'Om nettstaden',
  },
  secondColumnParagraph1: {
    en: 'Privacy statement',
    nb: 'Personvernerklæring',
    nn: 'Personvernerklæring',
  },
  secondColumnParagraph1Link: {
    en: 'https://www.innovasjonnorge.no/en/privacydeclaration/',
    nb: 'https://www.innovasjonnorge.no/no/privacydeclaration/',
    nn: 'https://www.innovasjonnorge.no/no/privacydeclaration/',
  },
  secondColumnParagraph2: {
    en: 'Declaration of availability',
    nb: 'Tilgjengelighetserklæring',
    nn: 'Tilgjengelegheitserklæring',
  },
  secondColumnParagraph2Link: {
    en: 'https://uustatus.no/nb/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1',
    nb: 'https://uustatus.no/nb/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1',
    nn: 'https://uustatus.no/nn/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1',
  },
  secondColumnParagraph3part1: {
    en: 'This website uses ',
    nb: 'Dette nettstedet bruker ',
    nn: 'Denne nettstaden brukar ',
  },
  secondColumnParagraph3part2: {
    en: 'cookies',
    nb: 'cookies',
    nn: 'cookies',
  },
};
