import React from 'react';
import { Button, Notification } from '@in/component-library';
import './FormSummaryValidation.scss';
import { useFormContext } from '../../hooks/useFormContext';
import { mapPageFields } from './fieldMapper';
import { FormState } from '../../context/form-reducer';
import { useLocalizer } from '../../hooks';
import { PushSiteImproveEvent } from '../../utils/siteimproveEvents';

const mapFormSummary = (formState: FormState) => {
  var pages = formState.currentPage === formState.form.pages.length - 1 ? formState.form.pages : [formState.form.pages[formState.currentPage]];

  return pages.map((p: any) => {
    const fields = mapPageFields(p, formState, true);

    return {
      ...p,
      fields: fields,
      hasError: fields.filter((f: any) => f.validations.length > 0).length > 0,
    };
  });
};

const FieldSummaryValidation: React.FC<{ field: any; onFieldClick: () => void }> = ({ field, onFieldClick }) => {
  if (field.validations.length === 0) return null;

  const onFieldClicked = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    PushSiteImproveEvent('SummaryErrorList', field.name);
    onFieldClick();
  };

  return (
    <li>
      <span className="displayFieldValidationErrorText remove-button-styling" onClick={(event) => onFieldClicked(event)} role="button" tabIndex={0}>
        {field.label}
      </span>
    </li>
  );
};

export const FormSummaryValidation: React.FC<{ goToField: (steps: number, fieldid: string) => void }> = (props) => {
  const { formState } = useFormContext();
  const { l } = useLocalizer('Form');
  const [displayValidationErrorText, setDisplayValidationErrorText] = React.useState(true);

  const formSummary = mapFormSummary(formState);

  const fieldsWithValidationError = formSummary.flatMap((p: any) =>
    p.fields
      .filter((f: any) => f.validations.length > 0)
      .map((field: any) => {
        return (
          <FieldSummaryValidation
            key={`summary-${p.pageIndex}-${field.name}`}
            field={field}
            onFieldClick={() => {
              props.goToField(p.pageIndex, field.name);
            }}
          />
        );
      })
  );

  const toggleDisplayAllValidationError = () => {
    return setDisplayValidationErrorText((prevState) => !prevState);
  };

  if (fieldsWithValidationError.length > 0)
    return (
      <Notification className="formSummaryValidation" fullWidth type="error">
        <span className="validationTitle extraSpace">{l('formPageValidationSummaryTitle')}</span>
        <ul>
          {fieldsWithValidationError.length > 3 ? (
            <>{displayValidationErrorText ? fieldsWithValidationError.slice(0, 3) : fieldsWithValidationError}</>
          ) : (
            <>{fieldsWithValidationError}</>
          )}
        </ul>
        {fieldsWithValidationError.length > 3 && (
          <Button size="medium" variant="link" onClick={toggleDisplayAllValidationError}>
            {displayValidationErrorText ? `${l('displayValidationErrorText')} (${fieldsWithValidationError.length})` : `${l('displayLess')}`}
          </Button>
        )}
      </Notification>
    );

  return null;
};
