import React from 'react';
import { FileItem, FileStatus, FileUpload } from '@in/component-library';

export const FileUploadWrapper = (props: any) => {

  const files = (): any[] => {
    if (!props.attachments)
      return [];

    return props.attachments.map((a: any) => {
      return {
        fileName: a.name,
        fileType: a.contentType,
        status: FileStatus.Ready,
        id: a.uploadName,
        fileSize: a.length
      } as FileItem
    });
  }

  const fileTypesRule = props.fileValidationRules?.find((r: any) => r.ruleType === "fileTypesRule");

  const fileTypes = () => {
    if (!fileTypesRule)
      return "";

    return fileTypesRule.fileTypes.join(',');
  }

  return (
    <FileUpload
      className='data-hj-suppress'
      name={props.name}
      errorMsg={props.errorMsg}
      label={props.label}
      fileTypes={fileTypes()}
      files={files()} 
      uploadCallback={props.onFileUpload} 
      deletionCallback={props.onFileDelete}
      helpText={props.helpText ?? undefined}
      uploadText={props.chooseFilesLabel ?? undefined}
      dragText={props.dragFilesLabel ?? undefined}
      retryUploadText={props.retryFileLabel ?? undefined}
      fileUploadedText={props.fileUploadedLabel ?? undefined}
      fileUploadingText={props.fileUploadingLabel ?? undefined}
      fileDeletingText={props.fileDeletingLabel ?? undefined}
      fileDeleteText={props.fileDeleteLabel ?? undefined}
    />
  );
}
