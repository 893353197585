import { Button, FaIcons } from '@in/component-library';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ApplicationCard from '../../components/ApplicationCard/ApplicationCard';
import { useLocalizer } from '../../hooks/useLocalizer';
import { getForms } from '../../services/forms.service';
import './Home.scss';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../components/loader/loader';

const Home: FC = () => {
  const { l } = useLocalizer('Form');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [forms, setForms] = useState<any>(null);

  const createForm = () => {
    navigate({ pathname: '/form', search: searchParams.toString() });
  };

  useEffect(() => {
    setFormsState();
  }, []);

  const setFormsState = async () => {
    const allForms = await getForms();
    setForms(allForms.filter((e: any) => e.status === 'Draft'));
  };
  if (forms === null) {
    return <Loader aria-label="Laster innhold" />;
  } else {
    if (forms.length === 0) {
      createForm();
    }
  }

  return (
    <div className="home">
      <div className="home__info">
        <h1 className="home__info--title">{l('continueTitle')}</h1>
        <p className="home__info--subtitle">{l('continueSubTitle')}</p>
      </div>
      <div>
        {forms.map((e: any) => {
          const date = new Date(e.modifiedOn);
          return (
            <ApplicationCard
              key={e.id}
              title={e.title}
              secondaryTitle={l(e.applicationType)}
              footerLabel={l('draft')}
              footerIcon="fa-lock-open"
              footerLabelBackground
              footerContent={l('lastSave') + date.toLocaleDateString('no-NO')}
              href={`form/${e.id}${e.applicationType ? '?formType=' + e.applicationType : ''}`}
            />
          );
        })}
        <Button variant="outlined" onClick={() => createForm()} iconName={FaIcons.PlusLight}>
          {' '}
          {l('createNewForm')}
        </Button>
      </div>
    </div>
  );
};

export default Home;
