import { LocalizedString } from '../../utils/localizer';

export const VMAPartners: LocalizedString = {
  tabsTitleAll: {
    nb: 'Alle',
    nn: 'Alle',
    en: 'All',
  },
  tabpanelTitle: {
    nb: 'samarbeidspartner(e)',
    nn: 'samarbeidspartnar(ar)',
    en: 'partner(s)',
  },
  backButtonLabel: {
    nb: 'Tilbake',
    nn: 'Tilbake',
    en: 'Back',
  },
  closeButtonLabel: {
    nb: 'Lukk',
    nn: 'Lukk',
    en: 'Close',
  },
  websiteLinkLabel: {
    nb: 'Nettside',
    nn: 'Nettside',
    en: 'Website',
  },
};
