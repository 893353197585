import { SanityCaseProcess, SanityCaseProcessStep } from "../models/sanity-case-process";
import { ApplicationStatus } from "../models/statuspage/application-status";
import { get } from "../apis/utils";
import { CaseProcess } from "../models/statuspage/case-process";
import { addDays } from 'date-fns';

export const getCaseProcess = async (formId: string | undefined) => await get<CaseProcess>(`v1/form/${formId}/status`);

export const convertFormDates = (form: any): CaseProcess => ({
  ...form,
  createdOn: new Date(form.createdOn),
  submittedOn: form.submittedOn ? new Date(form.submittedOn) : null,
  modifiedOn: new Date(form.modifiedOn),
});

export const modifySanityCaseProcesses = (sanityCaseProcesses: SanityCaseProcess[]): SanityCaseProcess[] =>
  sanityCaseProcesses.map((caseProcess) => ({
    ...caseProcess,
    steps: caseProcess.steps.map((step) => ({
      ...step,
      status: step.status === ApplicationStatus.Submitted ? ApplicationStatus.UnderConsideration : step.status,
    })),
  }));

export const findActiveStep = (formWithAttachments: CaseProcess, steps: SanityCaseProcessStep[]): number | null => { 
  const stepIndex = steps.findIndex((step) => step.status === formWithAttachments.status);
  return stepIndex !== -1 ? stepIndex : null;
};

//add date to steps
export const mapStepsWithDate = (sanityCaseProcesses: SanityCaseProcess[], formWithAttachments: CaseProcess): SanityCaseProcessStep[] =>
  sanityCaseProcesses.flatMap((caseProcess) => mapCaseProcessSteps(caseProcess, formWithAttachments)
  );

//map dates in caseprocess timeline
export const mapCaseProcessSteps = (caseProcessModel: SanityCaseProcess, formWithAttachments: CaseProcess): SanityCaseProcessStep[] =>
  caseProcessModel.steps.map((step) => {
    const baseDate = formWithAttachments.modifiedOn ? new Date(formWithAttachments.modifiedOn) : new Date(formWithAttachments.createdOn);
    let date: Date;

    const status = step.status === ApplicationStatus.Submitted ? ApplicationStatus.UnderConsideration : step.status;

    switch (status as keyof typeof ApplicationStatus) {
      case ApplicationStatus.Draft:
        date = new Date(formWithAttachments.createdOn);
        break;
      case ApplicationStatus.UnderConsideration:
        date = new Date(formWithAttachments.modifiedOn);
        break;
      case ApplicationStatus.PendingDecision:
        if(formWithAttachments.messages?.length){
          date = new Date(formWithAttachments.messages[0].createdOn);
          break;
        }
        date = addDays(baseDate, step.timeEstimateDays);
        break;
      default:
        date = baseDate;
        break;
    }
    return {
      ...step,
      status,
      date,
    };
  });