import React, { useContext, useEffect, useState } from 'react';
import { Button, ErrorMessage, Event, ProgressBarFull } from '@in/component-library';

import { postCourseEnrollment } from '../../apis/set-courseEnrollment';
import { CourseEnrollment } from '../../models/statuspage/course-enrollment';
import { useTranslations } from '../../hooks/use-translations';
import { RecommendedCourseProps } from './recommended-course-props';
import { AppContext } from '../../context/app-context';

export const RecommendedCourse: React.FC<RecommendedCourseProps> = ({ course, existingEnrollment, caseProcessData, headingLevel }) => {
  const { translations } = useTranslations();
  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [enrollment, setEnrollment] = useState<CourseEnrollment>();

  useEffect(() => {
    setEnrollment(existingEnrollment);
  }, [existingEnrollment]);

  const createCourseEnrollment = async (externalId: string) => {
    setIsLoading(true);
    const result = await postCourseEnrollment(externalId);
    const createdEnrollment: CourseEnrollment = result?.body;
    if (result.code === 200) {
      setEnrollment(createdEnrollment);
      setError('');
    } else {
      setError('Klarte ikke å legge til kurset i mine kurs');
    }
    setIsLoading(false);
  };

  const hasEnrollment = enrollment?.percentageCompleted != null;
  const Heading = headingLevel;

  return (
    <div className="case-handler">
      <div>
        <span className="case-handler__image-container">
          <img className="case-handler__image" alt={course.caseHandlerImageA?.imgAlt} src={course.caseHandlerImageA?.url} />
        </span>
      </div>

      <div>
        <div className="case-handler__recommendation">
          <Heading className="case-handler__title">
            {translations.formStatusGreeting} <span className="data-hj-suppress">{caseProcessData?.modifiedByPerson}</span>!
          </Heading>
          <p className="case-handler__description">{course.caseHandlerRecommendation}</p>
        </div>
        <Event
          className="timeline__course"
          key={course.identifier}
          href={hasEnrollment ? enrollment.href.replace('/courses/', '/courses/take/') : undefined}
          title={course.title}
        >
          {hasEnrollment ? (
            <>
              <p className="enrollment__finishedText">
                {`${enrollment.percentageCompleted > 0 ? translations.enrollmentCompleted : translations.enrollmentNotStarted} ${enrollment.percentageCompleted} %`}
              </p>
              <ProgressBarFull className="progressbar" percentage={enrollment.percentageCompleted} />
              <Button iconPosition="left" size="medium" theme="neutral" variant="outlined">
                {enrollment.percentageCompleted > 0 && enrollment.percentageCompleted < 100
                  ? translations.courseStarted
                  : enrollment.percentageCompleted < 1
                  ? translations.courseNotStarted
                  : translations.courseCompleted}
              </Button>
            </>
          ) : state.configuration.featureFlags?.featureAddToMyCourseEnabled ? (
            <Button iconPosition="left" size="medium" theme="neutral" variant="outlined" isLoading={isLoading} onClick={() => createCourseEnrollment(course.identifier)}>
              {translations.courseAdd}
            </Button>
          ) : (
            <Button iconPosition="left" size="medium" theme="neutral" variant="outlined" isLoading={isLoading} href={course.url}>
              Gå til kurs
            </Button>
          )}
          {error && (
            <div aria-live="polite">
              <ErrorMessage errorMessage={error} />
            </div>
          )}
        </Event>
      </div>
    </div>
  );
};
