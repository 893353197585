import React from 'react';
import './InlineReadOnlyTextFieldWrapper.scss';

export const InlineReadOnlyTextFieldWrapper = (props: any) => {
  return (
    <div className="inlineReadOnlyTextField">
      <label htmlFor={props.name}>{props.label}</label>
      <span id={props.name} className="inlineReadOnlyTextField-value">{props.value}</span>
    </div>
  );
}
