import React, { FC } from "react";
import { Message } from "../../models/statuspage/message";

export interface MessageBubbleProps {
  message: Message;
  display?: string;
}

const MessageBubble: FC<MessageBubbleProps> = ({ message, display }) => {
  return (
    <>
      <div className={`messages__bubble messages__bubble${display}`}>
          <div
            className="messages___bubble--body data-hj-suppress"
            dangerouslySetInnerHTML={{ __html: message.content }}
          />
      </div>
    </>
  );
};

export default MessageBubble;
