import React from 'react';
import { FormField } from '../FormField';

export const GroupFieldWrapper = (props: any) => {
  return props.fields && props.fields.length > 0 ? (
    <section>
      <label className="fieldLabel">{props.label}</label>
      {props.fields.map((field: any, index: number) => (
        <FormField
          key={index}
          {...props}
          field={{
            key: field.key,
            name: field.name,
            fieldType: field.fieldType,
          }}
        />
      ))}
    </section>
  ) : (
    <></>
  );
};
