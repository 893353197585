import { FormState } from '../../context/form-reducer';

export const mapFields = (field: any, state: FormState) => {

  const realField = state.form.fields.find((f: any) => f.key === field.key);

  switch (field.fieldType) {
    case 'groupField':
      return realField.fields.map((f: any) => mapFields({ name: f.name, key: f.key, fieldType: f.fieldType }, state));
    default:
      return realField;
  }
}

export const mapPageFields = (page: any, state: FormState, appendValidations?: boolean) => {
  let fields;
  if(page.pageType === 'sectionedFormPage') {
    fields = page.pages.flatMap((subPage: any) => subPage.fields);
  }
  else{
    fields = page.fields;
  }

  const mappedFields = fields ? [].concat(...fields.map((f: any) => mapFields(f, state))) : [];

  if (appendValidations)
    return mappedFields.map((f: any) => {
      return {
        ...f,
        validations: state.validationByFieldName[f.name] ?? []
      }
    });

  return mappedFields;
}
