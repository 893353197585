import React from 'react';
import { Radiobutton, RadioButtonOptionProps } from '@in/component-library';
import './RadioButtonFieldWrapper.scss';

export const RadioButtonWrapper = (props: any) => {
  const mapOptions = (options: RadioButtonOptionProps[]) => {
    return options.map((option: RadioButtonOptionProps) => {
      return {
        ...option,
        selected: props.value === option.value,
      };
    });
  };

  const localOptions = mapOptions(props.options);

  return <Radiobutton key={props.name} {...props} className="form-page radiobutton" options={localOptions} onChange={props.onChange} />;
};
